<template>
  <div
    v-if="canDismissibleShow(name)"
    class="relative mx-auto mt-0 flex w-full max-w-7xl items-center overflow-hidden lg:mt-8"
  >
    <div class="flex-1">
      <div class="bg-primary-800 flex h-72 w-full items-center justify-end lg:block lg:h-auto lg:bg-transparent">
        <img
          :src="
            gradeBand === 'k5'
              ? require('images/themes/klasses-banner-k5.png')
              : require('images/themes/klasses-banner-612.png')
          "
          :alt="$t('theme_banner.bg_alt')"
          class="absolute h-full w-auto max-w-max opacity-20 lg:relative lg:h-auto lg:w-full lg:max-w-full lg:opacity-100"
        />
      </div>
      <div class="absolute left-0 top-0 m-auto flex h-full flex-row items-center px-8">
        <div>
          <h3 class="text-inverted font-display m-0 text-4xl font-semibold">{{ $t('theme_banner.heading') }}</h3>
          <p class="text-inverted mt-2 text-lg">{{ $t('theme_banner.subhead') }}</p>
          <div class="flex space-x-4">
            <LzButton class="btn btn--primary mt-4 gap-2" @click="openTheme">
              <InlineSvg path="icons/picture" class="w-6 text-white" />
              {{ $t('theme_banner.theme_cta_label') }}
            </LzButton>
            <LzButton class="btn btn--primary mt-4 gap-2" @click="openAvatar">
              <InlineSvg path="icons/user" class="w-6 text-white" />
              {{ $t('theme_banner.avatar_cta_label') }}
            </LzButton>
          </div>
        </div>
      </div>
    </div>
    <button
      class="absolute right-0 top-0 cursor-pointer p-2"
      :aria-label="$t('common.close')"
      @click="closeDismissible(name)"
    >
      <InlineSvg path="icons/x-circle" class="w-6 text-white" />
    </button>
  </div>
</template>

<script setup>
import LzButton from './LzButton'
import InlineSvg from './InlineSvg'
import { useDismissibles } from 'vue_features/shared/composables'
import { openModal } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

const name = 'ThemeBanner'

const props = defineProps({
  showCountLimit: {
    type: Number,
    default: null,
  },
})

const { gradeBand } = useCurrentUserStore()
const { showDismissible, closeDismissible, canDismissibleShow } = useDismissibles()
showDismissible(name, { limit: props.showCountLimit })
const openTheme = () => {
  openModal('ThemeModal')
}
const openAvatar = () => {
  openModal('AvatarModal')
}
</script>
